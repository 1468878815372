<template>
    <!-- 右侧悬浮按钮 -->
    <div class="rightBox">
        <ul>
            <li class="rbLi">
                <div class="rlIcon one">
                    <img src="http://iv.vu818.com/img/vu818.png" class="" alt="" />
                </div>
                <div class="rbLeftBox">
                    <div>
                        <ul>
                            <li class="rlbLi">在线客服</li>
                            <li class="rlbLi">
                                <img :src="getWebData().wechat_visitor" alt="" class="rlbImg" />
                            </li>
                            <li class="rlbLi">
                                <p class="rllP">售前</p>
                                <p class="rllP">{{ getWebData().phone }}</p>
                                <p class="rllP">7*24获取专业工程师的帮助，快速解决您的问题</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li class="rbLi">
                <div class="rlIcon two">
                    <img src="http://iv.vu818.com/img/vu818.png" class="" alt="" />
                </div>
                <div class="kefu-list">
                    <div v-if="getWebData().visitor">
                        <p v-for="(el, i) in getWebData().visitor.split(',')" :key="el">
                            <span> 技术{{ i + 1 }}: </span>
                            <a :href="`http://wpa.qq.com/msgrd?v=3&uin=${el}&site=qq&menu=yes`" target="_blank">
                                <img :src="'./img/qq.gif'" alt="" />
                            </a>
                        </p>
                    </div>
                </div>
            </li>
            <li class="rbLi">
                <div class="rlIcon three">
                    <img src="http://iv.vu818.com/img/vu818.png" class="" alt="" />
                </div>
                <div class="rbLeftBox">
                    <div>
                        <ul>
                            <li class="rlbLi">公司号</li>
                            <li class="rlbLi">
                                <img :src="getWebData().public_phone" alt="" class="rlbImg" />
                            </li>
                            <li class="rlbLi">
                                <p class="rllP">售后</p>
                                <p class="rllP">{{ getWebData().after_sales_phone }}</p>
                                <p class="rllP">7*24获取专业工程师的帮助，快速解决您的问题</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
        <div class="rbTop" @click="backTop" v-show="flag_scroll">
            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="rtImg" />
        </div>
    </div>
</template>

<script>
export default {
    name: "rightBox",
    inject: ["getWebData"],
    data() {
        return {
            scroll: 0,
            // 回到顶部按钮显隐
            flag_scroll: false,
            scroll: 0,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
        // 回到顶部
        backTop() {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
        },
        // 显示隐藏回到顶部
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop;
            // console.log(scrollTop);
            if (scrollTop > 500) {
                this.flag_scroll = true;
            } else {
                this.flag_scroll = false;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.rightBox {
    width: 50px;
    // height: 200px;
    // height: 220px;
    // background: linear-gradient(to right, #7781f1, #7781f1e0);
    background-color: white;

    box-shadow: 1px 1px 10px 1px #ccc;
    position: fixed;
    top: 280px;
    right: 12px;
    color: white;
    border-radius: 30px;
    // overflow: hidden;
    z-index: 1800;
    ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .rbLi {
            width: 100%;
            text-align: center;
            padding: 10px 0;
            transition: 0.5s;
            .rlIcon {
                height: 35px;
                margin: 0 auto;
                overflow: hidden;
                position: relative;
                img {
                    width: 700px;
                    position: absolute;
                    top: -658px;
                    // right: -210px;
                }
            }
            .one {
                width: 30px;
                img {
                    right: -216px;
                }
            }
            .two {
                width: 30px;
                img {
                    right: -189px;
                }
            }
            .three {
                width: 35px;
                img {
                    right: -244px;
                }
            }
            .rbLeftBox {
                width: 220px;
                height: 340px;
                position: absolute;
                top: -80px;
                right: 50px;
                display: none;
                & > div {
                    width: 200px;
                    height: 340px;
                    border-radius: 10px;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    box-shadow: 1px 1px 10px 1px #7781f1e0;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        .rlbLi:nth-child(1) {
                            line-height: 50px;
                            height: 50px;
                        }
                        .rlbLi {
                            width: 90%;
                            min-height: 30px;
                            line-height: 30px;
                            text-align: center;
                            margin: 0 auto;
                            border-bottom: 1px solid gainsboro;
                            .rllP {
                                text-align: left;
                            }
                            .rlbImg {
                                width: 70%;
                                // height: 70%;
                                margin: 0 auto;
                                margin-top: 25px;
                                box-sizing: border-box;
                                border-radius: 20px;
                            }
                            .rllBtn {
                                width: 120px;
                                height: 40px;
                                margin: 0 auto;
                                line-height: 40px;
                                text-align: center;
                                border-radius: 10px;
                                background-color: #8f98f5;
                            }
                        }
                        .rlbLi:nth-child(3) {
                            border: none;
                        }
                    }
                }
            }
            .kefu-list {
                width: 180px;
                // height: 200px;
                position: absolute;
                top: 0px;
                right: 50px;
                visibility: hidden;

                padding-right: 20px;
                & > div {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    border-radius: 10px;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    box-shadow: 1px 1px 10px 1px #7781f1e0;
                    padding: 10px 0;
                    p {
                        margin: 10px 0;
                        img {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        .rbLi:hover {
            background-color: #7781f1;
            .rbLeftBox {
                display: block;
                transition: 1s;
            }
            .kefu-list {
                visibility: visible;
            }
            .rlIcon {
                img {
                    top: -690px;
                }
            }
        }
        .rbLi:first-of-type {
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
        }
        .rbLi:last-of-type {
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }
    .rbTop {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 50%;
        position: fixed;
        top: 480px;
        right: 12px;
        // background: linear-gradient(to right, #7781f1, #7781f1e0);
        box-shadow: 1px 1px 10px 1px gainsboro;
        background-color: white;
        transition: 0.5s;
        overflow: hidden;
        .rtImg {
            position: absolute;
            width: 1954px;
            height: 2900px;
            top: -110px;
            right: -41px;
            cursor: pointer;
        }
    }
}
</style>
