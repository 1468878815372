<template>
    <div class="nhContent2">
        <div class="nc2Box1">
            <div class="nb1Input">
                <input type="search" placeholder="搜索产品名称" />
                <!-- <div class="seIcon">
                    <i class="iconfont icon-search"></i>
                </div> -->
            </div>
            <div class="nb1Lis">
                <ul>
                    <li class="nb1Li" v-for="item in tops" :key="item.id">
                        <a href="#" @click.prevent="open(item.url)">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="nc2Box2">
            <div class="nb2Top">热门产品</div>
            <div class="nb2Content">
                <div class="ncLeft">
                    <template v-for="(item, index) in hots">
                        <div class="nclBox" v-if="index < 3" :key="item.id">
                            <a href="#" @click.prevent="open(item.url)">
                                <div class="nlb1">0{{ index + 1 }}</div>
                                <div class="n1b2">
                                    <p class="n2P">{{ item.title }}</p>
                                    <p class="n2P">{{ item.intro }}</p>
                                </div>
                            </a>
                        </div>
                    </template>
                </div>
                <div class="ncCenter">
                    <ul>
                        <template v-for="(item, index) in hots">
                            <li class="nccLi" v-if="index >= 3" :key="item.id">
                                <a href="#"> 0{{ index + 4 }}&nbsp;&nbsp;{{ item.title }}</a>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="ncRight">
                    <div class="ncrBox">
                        <img src="http://iv.vu818.com/img/side-bg5.jpg" class="ncrImg" />
                        <div class="ncrText">
                            <p class="ncrT">威有直播</p>
                            <p class="ncrT">支持多样上传SDK，媒资处理，视频AL，数据分析，播放器SDK等功能的云端一体化解决方案。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nc2Box3">
            <div class="nb3Top">最新产品</div>
            <div class="nb3List">
                <ul>
                    <li class="nb3Li" v-for="item in news" :key="item.id">
                        <a href="#" @click.prevent="open(item.url)">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            tops: [],
            hots: [],
            news: [],
        };
    },
    async created() {
        let {
            data: { data },
        } = await axios.get("/api/shop/show");
        this.tops = data.filter(e => e.type == "top");
        this.hots = data.filter(e => e.type == "hot");
        this.news = data.filter(e => e.type == "new");
    },
    methods: {
        open(url) {
            if (url.indexOf("http://") >= 0 || url.indexOf("https://") >= 0) {
                window.open(url);
            } else {
                window.open("http://" + url);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.nhContent2 {
    width: 80%;
    height: 100%;
    float: left;
    padding-top: 20px;
    box-sizing: border-box;
    background-color: #fdfdfd;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow-y: scroll;
    .nc2Box1 {
        width: 95%;
        height: 50px;
        margin: 0 auto;
        line-height: 50px;

        .nb1Input {
            width: 350px;
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
            background-color: white;
            box-shadow: 1px 1px 10px 1px gainsboro;
            border-radius: 10px;
            float: left;
            input {
                width: 85%;
                height: 100%;
                border: none;
                float: left;
                padding-left: 10px;
                outline: none;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .seIcon {
                width: 15%;
                height: 100%;
                float: left;
                cursor: pointer;
                .icon-search {
                    font-size: 20px;
                }
            }
        }
        .nb1Lis {
            width: 500px;
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
            margin-left: 20px;
            float: left;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                // justify-content: space-around;
                .nb1Li {
                    min-width: 70px;
                    height: 30px;
                    font-size: 12px;
                    margin-left: 10px;
                    border-radius: 5px;
                    color: black;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
                .nb1Li:hover {
                    a {
                        color: white;
                    }
                    background: linear-gradient(to right, #da8cff, #7781f1);
                }
            }
        }
    }
    .nc2Box2 {
        width: 95%;
        height: 250px;
        margin: 0 auto;
        margin-top: 30px;

        .nb2Top {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: left;
            border-bottom: 1px solid gainsboro;
            font-size: 14px;
            font-weight: 900;
            color: #7781f1;
        }
        .nb2Content {
            width: 100%;
            height: 200px;
            margin-top: 10px;
            .ncLeft {
                width: 30%;
                height: 100%;
                float: left;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .nclBox {
                    width: 95%;
                    height: 50px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 10px;
                    border: 2px solid white;
                    box-sizing: border-box;
                    .nlb1 {
                        width: 20%;
                        height: 30px;
                        line-height: 30px;
                        font-size: 18px;
                        text-align: center;
                        float: left;
                    }
                    .n1b2 {
                        width: 80%;
                        height: 100%;
                        float: left;
                        text-align: left;
                        .n2P {
                            width: 100%;
                            height: 50%;
                            line-height: 25px;
                            font-size: 13px;
                            color: black;
                        }
                        .n2P:nth-child(2) {
                            color: gray;
                        }
                    }
                }
                .nclBox:hover {
                    .n2P {
                        color: #7781f1;
                    }
                }
            }
            .ncCenter {
                width: 25%;
                height: 100%;
                float: left;
                ul {
                    width: 95%;
                    height: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .nccLi {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 14px;
                        text-align: left;
                        color: black;
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .nccLi:hover {
                        a {
                            color: #7781f1;
                        }
                    }
                }
            }
            .ncRight {
                width: 45%;
                height: 100%;
                float: right;
                .ncrBox {
                    width: 100%;
                    height: 90%;
                    background-color: white;
                    border-radius: 10px;
                    margin-top: 10px;
                    position: relative;
                    .ncrImg {
                        width: 98%;
                        height: 95%;
                        margin: 0 auto;
                        margin-top: 5px;
                        border-radius: 10px;
                    }
                    .ncrText {
                        width: 200px;
                        height: 150px;
                        position: absolute;
                        top: 0;
                        top: 8%;
                        left: 5%;
                        text-align: left;
                        .ncrT {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            font-size: 15px;
                            color: black;
                            font-size: 20px;
                        }
                        .ncrT:nth-child(2) {
                            width: 100%;
                            height: 100px;
                            color: gray;
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
                .ncrBox:hover {
                    .ncrT:nth-child(1) {
                        color: #7781f1;
                    }
                }
            }
        }
    }
    .nc2Box3 {
        width: 95%;
        height: 200px;
        margin: 0 auto;
        margin-top: 30px;
        .nb3Top {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: left;
            border-bottom: 1px solid gainsboro;
            font-size: 14px;
            font-weight: 900;
            color: #7781f1;
        }
        .nb3List {
            width: 100%;
            height: 120px;
            margin-top: 10px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                // justify-content: space-around;
                flex-wrap: wrap;
                .nb3Li {
                    width: 20%;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    color: black;
                    font-size: 14px;
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
                .nb3Li:hover {
                    a {
                        color: #7781f1;
                    }
                }
            }
        }
    }
}
</style>
