import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/common.css";
import "@/assets/css/fullpage.min.css";
import "@/assets/fonts/iconfont.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "babel-polyfill";
import VueLazyload from "vue-lazyload";
import axios from "axios";
import wow from "wowjs";
import "animate.css";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";

import Avue from "@smallwei/avue";
import "@smallwei/avue/lib/index.css";
// import { apply } from "core-js/fn/reflect";

(function () {
    var userAgent = navigator.userAgent.toLowerCase();
    var isMobile = /mobile|android|iphone|ipad|phone/i.test(userAgent);
    var isPC = !isMobile;
    if (isMobile) {
        // mobile
        location.href = "https://vu818.com/mobile/";
    } else {
        // pc
        // location.href = "https://vu818.com/";
    }
})();

Vue.use(Avue);

// import { VueMasonryPlugin } from "vue-masonry";
// Vue.use(VueMasonryPlugin);
// import { VueMasonryPlugin } from "vue-masonry";
// app.use(VueMasonryPlugin);

Vue.use(AOS);
Vue.prototype.$AOS = AOS;
Vue.prototype.$wow = wow;
Vue.prototype.$bus = new Vue();

Vue.use(VueLazyload, {
    // 加载失败
    error: "https://iv.vu818.com/img/zwsj.png",
    // 加载中
    loading: "https://iv.vu818.com/img/vu818jz.png",
    lazyComponent: true,
    observer: true,
    observerOptions: {
        rootMargin: "0px",
        threshold: 0.1,
    },
});
Vue.use(ElementUI);
Vue.config.productionTip = false;
// 数字滚动插件
// import VueAnimateNumber from "vue-animate-number";
// Vue.use(VueAnimateNumber);
// 全屏滚动 vue-fullpage.js
import "fullpage.js/vendors/scrolloverflow";
import VueFullpage from "vue-fullpage.js";
import Cookies from "js-cookie";
Vue.use(VueFullpage);

// import AMap from 'vue-amap';
// Vue.use(AMap);
// 初始化vue-amap
// AMap.initAMapApiLoader({
//     // 申请的高德key
//     key: 'fb398406e5ab40b611ce186be930e678',
//     // 插件集合
//     plugin: [''],
// });

// axios.defaults.baseURL = "http://vytxx.cn"; //本地
axios.defaults.baseURL = "https://vuapi.vu818.com"; //线上

axios.interceptors.request.use(config => {
    let only_day = Cookies.get("only_day");
    let user_only = Cookies.get("user_only");
    if (only_day) {
        config.headers["Day"] = only_day;
    }
    if (user_only) {
        config.headers["User"] = user_only;
    }
    return config;
});
axios.interceptors.response.use(res => {
    let {
        data: { code, cookie },
    } = res;
    if (code == 200 && cookie && cookie.name == "only_day" && !Cookies.get(cookie.name)) {
        // 设置cookie用来限制用户提交表单
        let seconds = cookie.time;
        let expires = new Date(new Date() * 1 + seconds * 1000);
        Cookies.set(cookie.name, cookie.content, { expires });
    }
    return res;
});

// 跳转回到顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});

new Vue({
    router,
    render: h => h(App),
}).$mount("#app");
